import React, { useEffect } from "react"
import { useWindowSize } from "react-use"
import { navigate } from "gatsby"

import Layout from "../../../components/layout.js"
import Seo from "../../../components/seo.js"
import UserProfileEditor from "../../../components/UserProfileEditor/index.js"
import ProfileLayout from "../../../components/UserProfileLayout/index.js"
import { updateCurrentStudentData } from "../../../services/helpers"
import { ToastContainer } from "react-toastify"

import { useStudentStore } from "../../../../store.js"
import { DEFAULT_STUDENT } from "../../../const_values.js"

const ProfileEditorPage = () => {
  const isBrowser = typeof window !== "undefined"
  const { updateStore } = useStudentStore((store) => store)

  const checkStudentData = async () => {
    try {
      const updateSuccessResult = await updateCurrentStudentData()
      console.log("update success:", updateSuccessResult)

      if (!updateSuccessResult.success && isBrowser) {
        navigate("/student", { replace: true })
        updateStore("student", DEFAULT_STUDENT)
      }
    } catch (error) {
      console.error(error)
      if (isBrowser) {
        updateStore("student", DEFAULT_STUDENT)
        navigate("/student", { replace: true })
      }
    }
  }

  useEffect(() => {
    checkStudentData()
  }, [])

  const { height } = useWindowSize()

  return (
    <>
      <ToastContainer theme="colored" autoClose={2000} />
      <Layout layoutHeight={height}>
        <Seo title="Profil bearbeiten" />
        <ProfileLayout backLinkTarget="/student/profile">
          <UserProfileEditor />
        </ProfileLayout>
      </Layout>
    </>
  )
}

export default ProfileEditorPage
