import React from "react"
import { navigate } from "gatsby"

import UserProfileEditorDisplay from "./UserProfileEditorDisplay"
import { useMutation } from "react-query"
import { useGraphqlClientStore } from "../../../store.js"
// import graphqlClient, {
//   resetGraphQLClient,
// } from "../../services/graphql-client.js"
import { useStudentStore } from "../../../store.js"
import { UPDATE_STUDENT_BY_ID_MUTATION } from "../../graphql_requests"
import { DEFAULT_STUDENT } from "../../const_values"

const UserProfileEditorContainer = () => {
  const isBrowser = typeof window !== "undefined" // check if executing instance is the browser or the building process

  const { student, updateStudent, updateStore } = useStudentStore(
    (store) => store
  )
  const { graphqlClient, resetGraphqlClient } = useGraphqlClientStore(
    (store) => store
  )

  const updateStudentMutation = useMutation(
    (variables) =>
      graphqlClient.request(UPDATE_STUDENT_BY_ID_MUTATION, variables),
    {
      onSuccess: (data) => {
        console.log(data)
      },
      onError: (error) => {
        console.error(error)
      },
    }
  )

  const handleChange = ({ target: { name, value } }) => {
    console.log("change", { name }, { value })
    updateStudent(name, value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const { alias, age, gender } = event.target
    console.log({
      alias: alias.value,
      age: parseInt(age.value),
      gender: gender.value,
      id: student.id,
    })
    updateStudentMutation.mutate({
      alias: alias.value,
      age: parseInt(age.value),
      gender: gender.value,
      id: student.id,
    })
    if (isBrowser) navigate("/student/profile", { replace: true })
  }

  const handleLogout = (e) => {
    if (isBrowser) {
      updateStore("student", DEFAULT_STUDENT)
      resetGraphqlClient()
      window.localStorage.removeItem("jwt_student")
      navigate("/", { replace: true })
    }
  }

  return (
    <>
      {student.id && (
        <UserProfileEditorDisplay
          student={student}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleLogout={handleLogout}
        />
      )}
    </>
  )
}

export default UserProfileEditorContainer
