import React from "react"
import PropTypes from "prop-types"

import { TextInput, NumberInput, SexInput } from "../UserProfileFormBlocks"
import { UserProfileForm } from "../UserProfileFormBlocks/styles"

const UserProfileEditorDisplay = ({
  student,
  handleChange,
  handleSubmit,
  handleLogout,
}) => (
  <UserProfileForm onSubmit={handleSubmit}>
    <h1 style={{ marginLeft: "30px" }}>Profil bearbeiten</h1>
    <TextInput
      id="user-alias"
      name="alias"
      label="Wie möchtest du genannt werden?"
      handleChange={handleChange}
      value={student?.alias}
      type="text"
    />
    <NumberInput
      id="age"
      label="Alter"
      handleChange={handleChange}
      min={5}
      max={12}
      value={student?.age || 6}
      small
    />
    <SexInput
      handleChange={handleChange}
      sex={student?.gender || "female"}
      small
    />

    <nav>
      <button className="button button--medium" type="submit">
        Speichern
      </button>
      <button className="button button--medium logout" onClick={handleLogout}>
        Abmelden
      </button>
    </nav>
  </UserProfileForm>
)

UserProfileEditorDisplay.propTypes = {
  student: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleDelete: PropTypes.func,
  handleLogout: PropTypes.func,
}

export default UserProfileEditorDisplay
